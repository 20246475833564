<template>

  <v-row justify="center">
    <v-layout v-for="(item ,index) in menus" :key="index" style="flex: initial">
      <v-btn
          depressed
          v-if="item.subMenu === undefined"
          color="white"
          style="color: #4398ae"
          v-text="item.title"
          :href="item.path"

      ></v-btn>
      <v-menu
          v-else
          offset-y
          auto
          allow-overflow

      >
        <template v-slot:activator="{ on, attrs }">

          <v-btn
              depressed
              color="white"
              style="color: #4398ae"
              v-bind="attrs"
              v-on="on"

          >
            {{item.title}}

            <v-icon
                dark
                right
            >
              mdi-chevron-down
            </v-icon>
          </v-btn>

        </template>

        <v-list

        class="pa-0"
        >
          <v-list-item
              style="min-height: auto"
              class="pa-0"

              v-for="(subMenu, index) in item.subMenu"
              :key="index"

          >
            <v-btn
                style="border-radius: 0"
                width="100%"
                depressed
                v-if="subMenu.subMenu === undefined"
                color="primary"
                dark
                v-text="subMenu.title"
                :href="subMenu.path"

            ></v-btn>
            <v-menu
                v-else

                offset-x

            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    style="border-radius: 0"
                    width="100%"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"

                >
                  {{subMenu.title}}
                  <v-icon
                      dark
                      right
                  >
                    mdi-chevron-right
                  </v-icon>
                </v-btn>

              </template>

              <v-list   class="pa-0">
                <v-list-item
                    style="min-height: auto"
                    class="pa-0"
                    v-for="(subSubMenu, index) in subMenu.subMenu"
                    :key="index"
                >
                  <v-btn
                      style="border-radius: 0"
                      v-if="subSubMenu.subMenu === undefined"
                      width="100%"
                      color="primary"
                      dark
                      v-text="subSubMenu.title"
                      :href="subSubMenu.path"

                  ></v-btn>
                  <v-menu
                      v-else
                      open-on-hover
                      offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          style="border-radius: 0"
                          width="100%"
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"

                      >
                        {{subSubMenu.title}}
                        <v-icon
                            dark
                            right
                        >
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list  class="pa-0">
                      <v-list-item
                          style="min-height: auto"
                          class="pa-0"
                          v-for="(subSubSubMenu, index) in subSubMenu.subMenu"
                          :key="index"
                      >
                        <v-btn
                            style="border-radius: 0"
                            width="100%"
                            color="primary"
                            dark
                            v-text="subSubSubMenu.title"
                            :href="subSubSubMenu.path"

                        ></v-btn>
                      </v-list-item>
                    </v-list>

                  </v-menu>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list>
      </v-menu>


    </v-layout>
  </v-row>


</template>

<script>
import Vue from "vue";

export default {
  name: "Toolbar",
  data: () => ({
   menus:Vue.prototype.toolbarMenus
  }),

}
</script>

<style scoped>

</style>